<template>
  <v-app>
    <v-dialog scrollable v-model="isFlowDownloading" persistent width="344">
      <Loading
        :visible="isFlowDownloading"
        :message="'Obteniendo detalles del flujo...'"
      />
    </v-dialog>

    <v-app-bar elevation="4" app dense clipped-left color="primary">
      <div>
        <v-app-bar-nav-icon dark @click="drawer = !drawer" />
      </div>

      <v-tabs fixed-tabs dark>
        <v-tab
          v-for="(file, index) in files"
          :key="index"
          @click="onDocumentPressed(index)"
        >
          {{ formattedFileTitles[index] }}
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-navigation-drawer clipped app v-model="drawer">
      <div class="mx-4">
        <v-btn
          class="mt-2"
          outlined
          block
          color="primary"
          @click="onBackPressed"
        >
          <v-icon left> mdi-arrow-left </v-icon>
          Volver atrás
        </v-btn>
      </div>

      <div class="mx-4" v-if="itemsFlows.length > 0">
        <v-btn class="mt-2" color="primary" block @click="openAndGetFlows">
          <v-icon left> mdi-clipboard-flow </v-icon>
          Seleccionar flujo
        </v-btn>
      </div>
      <div class="mx-4">
        <v-btn
          class="mt-2"
          color="primary"
          block
          @click="recipientDialog = true"
        >
          <v-icon left> mdi-account-plus </v-icon>
          Añadir participante
        </v-btn>
      </div>

      <v-list subheader two-line class="mt-8">
        <v-subheader v-if="flow">
          <v-icon left> mdi-clipboard-flow </v-icon>
          <span class="text-truncate">Flujo: {{ flow.name }}</span>
        </v-subheader>
        <v-subheader v-else-if="itemsFlows.length > 0">
          <v-icon left> mdi-clipboard-flow </v-icon> No hay flujo
          seleccionado</v-subheader
        >
        <v-subheader>
          <v-icon left> mdi-account-multiple </v-icon>
          {{ formattedRecipientsListSubheader }}
        </v-subheader>

        <template v-for="(recipient, index) in recipients">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title v-text="recipient.name"></v-list-item-title>
              <v-list-item-subtitle
                v-text="recipient.email"
              ></v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-btn
                  v-if="
                    recipientHasPositionateSignatureRole(recipients[index]) &&
                      !recipientIsPositionedInDocument(recipients[index])
                  "
                  small
                  class="pa-0"
                  text
                  @click="onPositionateRecipientPressed(index)"
                  color="primary"
                  >Añadir firma</v-btn
                >
                <v-btn
                  v-if="
                    recipientHasPositionateSignatureRole(recipients[index]) &&
                      recipientIsPositionedInDocument(recipients[index])
                  "
                  small
                  class="text--darken-2 pa-0"
                  text
                  @click="onRemoveSignaturePressed(index)"
                  color="red"
                  >Eliminar firma</v-btn
                >
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                @click="onRemoveRecipientPressed(index)"
                class="text--darken-2 mb-8"
                color="red"
                ><v-icon dark> mdi-delete-outline </v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < recipients.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list>

      <div class="my-4 mx-4">
        <li
          class="red--text text--darken-1 text-caption"
          v-for="(error, index) in documentSetRecipientErrors"
          :key="index"
        >
          {{ error }}
        </li>
      </div>

      <div class="mx-4">
        <v-btn
          class="my-4"
          color="primary"
          block
          @click="onCreateDocumentSetPressed"
        >
          Crear Sobre
          <v-icon right> mdi-arrow-right </v-icon>
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <PDFViewer
          :file="files[selectedDocumentIndex]"
          @page-focus="onUpdateCurrentPage"
          @page-rendered="onPageRendered"
          @document-errored="onDocumentErrored"
        >
        </PDFViewer>
        <v-overlay :value="!isDocumentRendered">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog scrollable v-model="dialogMethodSign" min-width="300">
          <v-card>
            <v-card-title class="text-h5">
              Añadir flujo
            </v-card-title>

            <v-card-text>
              <v-form ref="form">
                <v-select
                  label="Seleccionar flujo"
                  :items="itemsFlows"
                  v-model="flowSelected"
                  required
                  outlined
                  dense
                  class="mt-3"
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialogMethodSign = false">
                Cerrar</v-btn
              >

              <v-btn color="primary" text @click="setFlowData">
                Seleccionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog scrollable width="600" v-model="recipientDialog" persistent>
          <v-card class="pa-1">
            <v-card-title class="text-h5">Añadir participante </v-card-title>
            <v-card-subtitle v-if="flow"
              >Flujo: {{ flow.name }}</v-card-subtitle
            >
            <v-card-text>
              <!--autocomplete removed 13/11/23 on purpose so that the user cannot make a mistake -->
              <v-text-field
                class="mt-2"
                v-model="newRecipient.name"
                outlined
                dense
                label="* Nombre del participante"
              ></v-text-field>

              <v-text-field
                v-model="newRecipient.email"
                outlined
                dense
                label="* Email del participante"
              ></v-text-field>

              <v-expansion-panels :value="panelOpened">
                <v-expansion-panel @click="togglePanel">
                  <v-expansion-panel-header expand-icon="mdi-menu-down"
                    >Opciones avanzadas</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-select
                      :disabled="flow !== null"
                      :items="getActionTypesAvailableForNewRecipient"
                      v-model="newRecipient.role"
                      label="* Acción del participante"
                      outlined
                      dense
                      class="mt-3"
                    ></v-select>
                    <div
                      v-if="recipientHasPositionateSignatureRole(newRecipient)"
                      class="text-caption mt-n4 mb-8 ml-2 info--text"
                    >
                      <v-icon color="info"> mdi-information-outline </v-icon>
                      La caja de firma aparecerá automáticamente en la página
                      actual de este documento y en la primera página del resto
                      de documentos. Se puede arrastrar para posicionar.
                    </div>
                    <vue-tel-input-vuetify
                      :inputOptions="vueTelInputOptions"
                      ref="vueTelInputRef"
                      class="ml-8 mt-n4"
                      :preferredCountries="recipientOtpPreferredCountries"
                      :ignoredCountries="recipientOtpIgnoredCountries"
                      :disabledFetchingCountry="true"
                      :defaultCountry="'ES'"
                      v-if="isPhoneRequiredForNewRecipient"
                      label="* Teléfono del participante"
                      placeholder=""
                      @input="onNewRecipientPhoneInput"
                      outlined
                      dense
                    >
                    </vue-tel-input-vuetify>

                    <v-select
                      :disabled="flow !== null"
                      :items="getAuthTypesAvailableForNewRecipient"
                      v-model="newRecipient.authType"
                      label="* Autenticación del participante"
                      outlined
                      dense
                    ></v-select>
                    <v-text-field
                      class="ml-8 mt-n4"
                      v-if="isCardIdRequiredForNewRecipient"
                      v-model="newRecipient.cardId"
                      label="* Card Id"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      :disabled="flow !== null"
                      class="ml-8 mt-n4"
                      v-if="isAccessKeyRequiredForNewRecipient"
                      v-model="newRecipient.accessKeyChallenge"
                      label="* Pregunta"
                      outlined
                      dense
                    ></v-text-field>

                    <v-text-field
                      class="ml-8 mt-n4"
                      v-if="isAccessKeyRequiredForNewRecipient"
                      v-model="newRecipient.accessKeyResponse"
                      label="* Respuesta"
                      outlined
                      dense
                    ></v-text-field>

                    <v-text-field
                      class="ml-8 mt-n4"
                      v-if="isAccessKeyRequiredForNewRecipient"
                      v-model="newRecipient.accessKeyFormat"
                      label="Formato"
                      outlined
                      dense
                    ></v-text-field>
                    <v-checkbox
                      class="mt-n2"
                      v-if="isCustomTextAvailableForNewRecipient"
                      v-model="newRecipient.includeCustomText"
                      label="Incluir nombre del participante y hora de firmado en la propia firma"
                    ></v-checkbox>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          {{ formattedAttachmentsTitleForNewRecipient }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-btn
                            class="ml-n3"
                            small
                            color="primary"
                            text
                            @click="onAddAttachmentPressed"
                          >
                            <v-icon left> mdi-paperclip </v-icon>
                            Añadir anexo
                          </v-btn>

                          <v-list class="ml-n3 mt-2">
                            <v-list-item
                              v-for="(attachment,
                              index) in newRecipient.attachments"
                              :key="index"
                            >
                              <v-list-item-content class="py-1">
                                <v-text-field
                                  v-model="
                                    newRecipient.attachments[index].description
                                  "
                                  label="* Nombre del anexo"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-list-item-content>

                              <v-list-item-action class="ml-4 mt-n4">
                                <v-checkbox
                                  v-model="
                                    newRecipient.attachments[index].required
                                  "
                                  label="Obligatorio"
                                ></v-checkbox>
                              </v-list-item-action>

                              <v-list-item-action class="mt-n4">
                                <v-btn
                                  small
                                  text
                                  color="error"
                                  @click="
                                    newRecipient.attachments.splice(index, 1)
                                  "
                                >
                                  <v-icon> mdi-delete-outline </v-icon>
                                  Eliminar
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="mt-4">
                <li
                  class="red--text text--darken-1"
                  v-for="(error, index) in recipientDialogErrors"
                  :key="index"
                >
                  {{ error }}
                </li>
              </div>
            </v-card-text>

            <v-card-actions class="pb-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="recipientDialog = false"
                >Cancelar</v-btn
              >

              <v-btn color="primary" text @click="addRecipient"
                >Añadir participante</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          scrollable
          width="600"
          v-model="createDocumentSetDialog"
          persistent
        >
          <v-card :disabled="isDocumentSetBeingCreated" class="pa-1">
            <v-card-title class="text-h5"> Crear sobre </v-card-title>
            <v-card-subtitle
              v-if="activeUserTeam && userProfile.teams.length > 1"
            >
              Equipo: {{ activeUserTeam.name }}
            </v-card-subtitle>
            <v-card-text>
              <v-text-field
                class="mt-2"
                v-model="documentSetDetails.name"
                outlined
                dense
                label="* Nombre del sobre"
              ></v-text-field>
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >Opciones avanzadas</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-text-field
                      :disabled="flow !== null"
                      v-model.number="documentSetDetails.expirationDaysTimeout"
                      label="* Vencimiento en días"
                      min="0"
                      type="number"
                      outlined
                      dense
                      class="mt-2"
                    >
                    </v-text-field>
                    <v-row
                      class="pa-0"
                      align-items="center"
                      align-content="center"
                    >
                      <v-col>
                        <v-checkbox
                          :disabled="flow !== null"
                          v-model="documentSetDetails.showReminderDays"
                          label="Añadir recordatorios"
                        >
                        </v-checkbox>
                      </v-col>

                      <v-col>
                        <v-text-field
                          :disabled="flow !== null"
                          v-if="documentSetDetails.showReminderDays"
                          v-model.number="documentSetDetails.reminderDays"
                          label="* Recordatorio en días"
                          type="number"
                          min="0"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-checkbox
                      v-if="recipients.length > 1"
                      class="mt-2"
                      v-model="paralelSigning"
                      label="Firmar en paralelo"
                    >
                    </v-checkbox>
                    <v-checkbox
                      :disabled="flow !== null"
                      class="mt-2"
                      v-model="documentSetDetails.ltv"
                      label="Firma de larga duración (LTV)"
                    >
                    </v-checkbox>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="mt-4">
                <li
                  class="red--text text--darken-1"
                  v-for="(error, index) in documentSetDialogErrors"
                  :key="index"
                >
                  {{ error }}
                </li>
              </div>
            </v-card-text>

            <v-card-actions class="pb-2">
              <v-spacer></v-spacer>
              <v-btn
                v-if="!isDocumentSetBeingCreated"
                color="primary"
                text
                @click="createDocumentSetDialog = false"
                >Cancelar</v-btn
              >

              <v-btn
                :loading="isDocumentSetBeingCreated"
                color="primary"
                text
                @click="onCreateDocumentSet"
                >Crear sobre</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import PDFViewer from "../../../components/pdf/PDFViewer.vue";
import Constants from "./ConstantsPSC";
import { InitFabricObjects } from "../../../utils/utils";
import { bus } from "../../../main";
import { FileToB64 } from "../../../utils/utils";
import Loading from "@/components/commons/Loading";

export default {
  name: "NewDocumentSet",

  components: {
    PDFViewer,
    Loading
  },

  props: {
    flowTokenId: String,
    files: Array,
    reference: {
      type: String,
      default: null
    },
    docId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      panelOpened: -1,
      drawer: true,
      isFlowDownloading: false,
      isDocumentSetBeingCreated: false,
      documentSetDetails: {},
      currentDocumentPage: 0,
      absoluteOverlay: true,
      isDocumentRendered: false,
      selectedDocumentIndex: 0,
      recipientDialog: false,
      createDocumentSetDialog: false,
      recipientActionTypes: Constants.PSC_RECIPIENT_ACTION_TYPES,
      recipientAuthTypes: Constants.PSC_RECIPIENT_AUTH_TYPES,
      recipientOtpPreferredCountries:
        Constants.PSC_RECIPIENT_OTP_PREFERRED_COUNTRIES,
      recipientOtpIgnoredCountries:
        Constants.PSC_RECIPIENT_OTP_IGNORED_COUNTRIES,
      newRecipient: {},
      newRecipientPhone: {},
      recipients: [],
      recipientDialogErrors: [],
      documentSetRecipientErrors: [],
      documentSetDialogErrors: [],
      flow: null,
      vueTelInputOptions: { showDialgCode: true },
      paralelSigning: false,
      errorMsg: "",
      dialogMethodSign: false,
      itemsFlows: [],
      flowSelected: null,
      isOpen: false
    };
  },

  beforeDestroy() {
    bus.$off("recipient-widget-modified");
    bus.$off("recipient-widget-removed");
  },

  mounted() {
    if (!this.files) {
      this.$router.back();
    } else {
      if (this.flowTokenId) {
        this.isFlowDownloading = true;
        this.$store
          .dispatch("getFlow", this.flowTokenId)
          .then(flow => {
            this.isFlowDownloading = false;
            this.handleFlowResponse(flow);
          })
          .catch(() => {
            this.isFlowDownloading = false;
            this.$router.push({ name: "RemoteSignaturesSent" });
            this.$toasted.global.toast_error({
              message: "No se ha podido obtener el detalle del flujo"
            });
          });
      } else {
        this.getFlows();
      }
    }
  },

  created() {
    InitFabricObjects();
    this.newRecipient = JSON.parse(
      JSON.stringify(Constants.PSC_DEFAULT_RECIPIENT)
    );

    this.newRecipientPhone = JSON.parse(
      JSON.stringify(Constants.PSC_RECIPIENT_DEFAULT_PHONE)
    );

    this.documentSetDetails = JSON.parse(
      JSON.stringify(Constants.PSC_DEFAULT_DOCUMENTSET)
    );

    bus.$on("recipient-widget-modified", payload => {
      var email = payload.recipientEmail;
      var recipientIndex = this.recipients
        .map(recipient => recipient.email)
        .indexOf(email);
      if (recipientIndex !== -1) {
        var widgetIndex = this.recipients[recipientIndex].widgets
          .map(widget => widget.documentId)
          .indexOf(this.selectedDocumentIndex); //TODO: review this.
        if (widgetIndex !== -1) {
          this.recipients[recipientIndex].widgets[widgetIndex].x = payload.x;
          this.recipients[recipientIndex].widgets[widgetIndex].y = payload.y;
          this.recipients[recipientIndex].widgets[widgetIndex].scaleX =
            payload.scaleX;
          this.recipients[recipientIndex].widgets[widgetIndex].scaleY =
            payload.scaleY;
          this.recipients[recipientIndex].widgets[widgetIndex].height =
            payload.height;
          this.recipients[recipientIndex].widgets[widgetIndex].width =
            payload.width;
        }
      }
    }),
      bus.$on("recipient-widget-removed", payload => {
        var email = payload.recipientEmail;
        var recipientIndex = this.recipients
          .map(recipient => recipient.email)
          .indexOf(email);
        var documentId = this.selectedDocumentIndex; //TODO: Review this.

        if (recipientIndex !== -1) {
          var widgetIndex = this.recipients[recipientIndex].widgets
            .map(widget => widget.documentId)
            .indexOf(documentId);
          if (widgetIndex !== -1) {
            this.recipients[recipientIndex].widgets.splice(widgetIndex, 1);
          }
        }
      });
  },

  computed: {
    formattedFileTitles() {
      return this.files.map(file => {
        if (file.name.length < 28) {
          return file.name;
        } else {
          return file.name.substring(0, 24) + "..";
        }
      });
    },
    formattedRecipientsListSubheader() {
      if (this.recipients.length === 0) {
        return "No hay participantes";
      }
      return "Participantes " + "(" + this.recipients.length + ")";
    },
    formattedAttachmentsTitleForNewRecipient() {
      if (this.newRecipient.attachments.length === 0) {
        return "Anexos";
      }
      return "Anexos (" + this.newRecipient.attachments.length + ")";
    },

    getActionTypesAvailableForNewRecipient() {
      let actionTypes = JSON.parse(
        JSON.stringify(
          this.recipientActionTypes.filter(
            actionType => actionType.value !== 20
          )
        )
      );
      if (this.newRecipient.authType === 40) {
        return actionTypes.filter(
          actionType =>
            actionType.value !== 40 &&
            actionType.value !== 50 &&
            actionType.value !== 51
        );
      }
      return actionTypes;
    },

    getAuthTypesAvailableForNewRecipient() {
      if (
        this.newRecipient.role === 40 ||
        this.newRecipient.role === 50 ||
        this.newRecipient.role === 51
      ) {
        return this.recipientAuthTypes.filter(authType => authType.value != 40);
      }
      return this.recipientAuthTypes;
    },

    isPhoneRequiredForNewRecipient() {
      return (
        this.newRecipient.role === 40 ||
        this.newRecipient.role === 50 ||
        this.newRecipient.role === 51 ||
        this.newRecipient.authType === 40 ||
        this.documentSetDetails.sendMethod === 2 ||
        this.documentSetDetails.sendMethod === 3
      );
    },
    isCardIdRequiredForNewRecipient() {
      return this.newRecipient.authType === 30;
    },
    isAccessKeyRequiredForNewRecipient() {
      return this.newRecipient.authType === 20;
    },
    isCustomTextAvailableForNewRecipient() {
      return this.newRecipient.role !== 20;
    },

    userProfile() {
      return this.$store.getters["user/userProfile"];
    },

    activeUserTeam() {
      var userProfile = this.$store.getters["user/userProfile"];
      var activeTeamTokenId = this.$store.getters.activeTeamTokenId;
      if (userProfile.teams && activeTeamTokenId) {
        return userProfile.teams.find(
          team => team.teamTokenId === activeTeamTokenId
        );
      }
      return null;
    },
    credits() {
      return this.$store.state.user.limitCredits &&
        this.$store.state.user.limitCredits !== "-"
        ? this.$store.state.user.limitCredits -
            this.$store.state.user.consumedCredits
        : 0;
    }
  },

  methods: {
    togglePanel() {
      this.$nextTick(() => {
        this.panelOpened = -1;
      });
    },

    handleFlowResponse(flow) {
      this.flow = flow;
      this.newRecipient.role = flow.actionType;
      this.newRecipient.authType = flow.authenticationType;
      this.newRecipient.accessKeyChallenge = flow.accessCode
        ? flow.accessCode.challenge
        : null;
      this.newRecipient.accessKeyResponse = flow.accessCode
        ? flow.accessCode.response
        : null;
      this.newRecipient.accessKeyFormat = flow.accessCode
        ? flow.accessCode.format
        : null;
      this.newRecipient.cardId = flow.cardId;
      this.documentSetDetails.ltv = flow.ltv;
      this.documentSetDetails.reminderDays = flow.reminderDays;
      if (flow.reminderDays !== 0) {
        this.documentSetDetails.showReminderDays = true;
      }
      this.documentSetDetails.expirationDaysTimeout =
        flow.expirationDaysTimeout;
      this.documentSetDetails.sendMethod = flow.sendMethod;
      this.documentSetDetails.corporateSignature = flow.corporateSignature;
    },

    onRemoveSignaturePressed(selectedRecipientIndex) {
      var recipient = this.recipients[selectedRecipientIndex];
      if (this.recipientIsPositionedInDocument(recipient)) {
        var widgetIndex = recipient.widgets
          .map(widget => widget.documentId)
          .indexOf(this.selectedDocumentIndex);
        if (widgetIndex !== -1) {
          bus.$emit("remove-recipient", {
            recipientEmail: recipient.email,
            pageNumber: recipient.widgets[widgetIndex].pageNumber
          });
          this.recipients[selectedRecipientIndex].widgets.splice(
            widgetIndex,
            1
          );
        }
      }
    },

    onRemoveRecipientPressed(selectedRecipientIndex) {
      var recipient = this.recipients[selectedRecipientIndex];
      if (this.recipientIsPositionedInDocument(recipient)) {
        var widgetIndex = recipient.widgets
          .map(widget => widget.documentId)
          .indexOf(this.selectedDocumentIndex);

        if (widgetIndex !== -1) {
          bus.$emit("remove-recipient", {
            recipientEmail: recipient.email,
            pageNumber: recipient.widgets[widgetIndex].pageNumber
          });
        }
      }
      this.recipients.splice(selectedRecipientIndex, 1);
    },

    recipientIsPositionedInDocument(recipient) {
      var widgetIndex = recipient.widgets
        .map(widget => widget.documentId)
        .indexOf(this.selectedDocumentIndex);
      return widgetIndex !== -1;
    },

    recipientHasPositionateSignatureRole(recipient) {
      return (
        recipient.role === 21 ||
        recipient.role === 30 ||
        recipient.role === 40 ||
        recipient.role === 50 ||
        recipient.role === 51 ||
        recipient.role === 60
      );
    },

    onUpdateCurrentPage(pageNumber) {
      this.currentDocumentPage = pageNumber;
    },

    onDocumentErrored() {
      this.$router.push({ name: "RemoteSignaturesSent" });
      this.$toasted.global.toast_error({
        message: "No se ha podido cargar el PDF"
      });
    },

    onPageRendered(pageNumber) {
      this.positionateRecipientsIfNeeded();
      if (!this.isDocumentRendered && pageNumber === 1) {
        this.isDocumentRendered = true;
      }
    },

    onDocumentPressed(index) {
      if (this.selectedDocumentIndex != index) {
        this.isDocumentRendered = false;
      }
      this.selectedDocumentIndex = index;
    },

    onBackPressed() {
      this.$router.back();
    },

    positionateRecipientsIfNeeded() {
      this.recipients.forEach(recipient => {
        recipient.widgets.forEach(widget => {
          if (widget.documentId === this.selectedDocumentIndex) {
            this.repositionateWidgetRecipient(recipient, widget);
          }
        });
      });
    },

    repositionateWidgetRecipient(recipient, widget) {
      bus.$emit("repositionate-recipient", {
        x: widget.x,
        y: widget.y,
        scaleX: widget.scaleX,
        scaleY: widget.scaleY,
        height: widget.height,
        width: widget.width,
        recipientEmail: recipient.email,
        recipientName: recipient.name,
        documentId: widget.documentId,
        pageNumber: widget.pageNumber
      });
    },

    addRecipient() {
      if (this.checkNewRecipient()) {
        this.recipientDialog = false;
        this.recipients.push(this.newRecipient);

        if (this.$refs.vueTelInputRef) {
          this.$refs.vueTelInputRef.$refs.input.reset();
        }

        this.newRecipient = JSON.parse(
          JSON.stringify(Constants.PSC_DEFAULT_RECIPIENT)
        );

        this.newRecipientPhone = JSON.parse(
          JSON.stringify(Constants.PSC_RECIPIENT_DEFAULT_PHONE)
        );
        if (this.flow) {
          this.handleFlowResponse(this.flow);
        }

        this.documentSetRecipientErrors = [];
        var recipientIndex = this.recipients.length - 1;

        if (
          this.recipientHasPositionateSignatureRole(
            this.recipients[recipientIndex]
          )
        ) {
          var coords = this.getDefaultCoordsBasedOnRecipientIndex(
            recipientIndex
          );
          for (var i = 0; i < this.files.length; i++) {
            var pageNumber = 1;
            if (i == this.selectedDocumentIndex) {
              pageNumber = this.currentDocumentPage;
            }
            var widget = {
              recipientEmail: this.recipients[recipientIndex].email,
              documentId: i,
              height: 76.5,
              width: 136.5,
              scaleX: 1,
              scaleY: 1,
              x: coords.x,
              y: coords.y,
              pageNumber: pageNumber
            };
            this.recipients[recipientIndex].widgets.push(widget);
            if (i === this.selectedDocumentIndex) {
              this.repositionateWidgetRecipient(
                this.recipients[recipientIndex],
                widget
              );
            }
          }
        }
      }
    },

    onPositionateRecipientPressed(recipientIndex) {
      var coords = this.getDefaultCoordsBasedOnRecipientIndex(recipientIndex);
      var recipient = this.recipients[recipientIndex];
      var widget = {
        recipientEmail: recipient.email,
        recipientName: recipient.name,
        documentId: this.selectedDocumentIndex,
        height: 76.5,
        width: 136.5,
        scaleX: 1,
        scaleY: 1,
        x: coords.x,
        y: coords.y,
        pageNumber: this.currentDocumentPage
      };
      this.recipients[recipientIndex].widgets.push(widget);
      this.repositionateWidgetRecipient(
        this.recipients[recipientIndex],
        widget
      );
    },

    getDefaultCoordsBasedOnRecipientIndex(recipientIndex) {
      var y = 0;
      var x = 0;
      var widgetWidth = 136.5;
      var widgetHeight = 76.5;

      switch (recipientIndex) {
        case 1:
          y = widgetHeight;
          break;
        case 2:
          x = widgetWidth;
          break;
        case 3:
          x = widgetWidth;
          y = widgetHeight;
          break;
        case 4:
          y = widgetHeight * 2;
          break;
        case 5:
          x = widgetWidth;
          y = widgetHeight * 2;
          break;
        case 6:
          x = widgetWidth * 2;
          break;
        case 7:
          x = widgetWidth * 2;
          y = widgetHeight;
          break;
        case 8:
          x = widgetWidth * 2;
          y = widgetHeight * 2;
          break;
        default:
          break;
      }
      return { x: x, y: y };
    },

    onAddAttachmentPressed() {
      this.newRecipient.attachments.push({
        description: "Anexo " + (this.newRecipient.attachments.length + 1),
        required: false
      });
    },

    onNewRecipientPhoneInput(_, { number, valid, country }) {
      this.newRecipientPhone.number = number.international;
      this.newRecipientPhone.valid = valid;
      this.newRecipientPhone.country = country && country.name;
    },

    onCreateDocumentSetPressed() {
      this.documentSetRecipientErrors = [];
      if (this.recipients.length === 0) {
        this.documentSetRecipientErrors.push("Añade al menos un participante.");
        return;
      }
      this.createDocumentSetDialog = true;
    },

    onCreateDocumentSet() {
      this.documentSetDialogErrors = [];
      if (!this.documentSetDetails.name) {
        this.documentSetDialogErrors.push(
          "Introduce un nombre válido para el sobre."
        );
      }

      if (!this.flow) {
        if (
          this.documentSetDetails.expirationDaysTimeout < 1 ||
          this.documentSetDetails.expirationDaysTimeout > 180
        ) {
          this.documentSetDialogErrors.push(
            "Introduce un vencimiento en días válido para el sobre. Los valores aceptados son entre 1 y 180."
          );
        }

        if (
          this.documentSetDetails.showReminderDays &&
          (this.documentSetDetails.reminderDays < 1 ||
            this.documentSetDetails.reminderDays > 180)
        ) {
          this.documentSetDialogErrors.push(
            "Introduce un recordatorio en días válido para el sobre. Los valores aceptados son entre 1 y 180."
          );
        }

        if (
          this.documentSetDetails.showReminderDays &&
          this.documentSetDetails.reminderDays >=
            this.documentSetDetails.expirationDaysTimeout
        ) {
          this.documentSetDialogErrors.push(
            "El recordatorio en días debe ser menor que el vencimiento en días."
          );
        }
      }

      if (this.documentSetDialogErrors.length === 0) {
        var documentSet = {};
        documentSet.senderMail = this.$store.getters["user/userProfile"].email;
        documentSet.senderName = this.$store.getters[
          "user/userProfile"
        ].fullName;
        documentSet.documentSetName = this.documentSetDetails.name;
        documentSet.expirationDaysTimeout = Math.round(
          this.documentSetDetails.expirationDaysTimeout
        );
        var reminderDays = 0;
        if (this.documentSetDetails.showReminderDays === true) {
          reminderDays = Math.round(this.documentSetDetails.reminderDays);
        }
        if (this.flow) {
          documentSet.corporateSignature = this.documentSetDetails.corporateSignature;
        }
        documentSet.reminderDays = reminderDays;
        documentSet.expirationDaysTimeout = this.documentSetDetails.expirationDaysTimeout;
        documentSet.ltv = this.documentSetDetails.ltv;
        documentSet.sendMethod = this.documentSetDetails.sendMethod;
        documentSet.paralelSigning = this.paralelSigning;
        if (this.reference !== null) {
          documentSet.reference = this.reference;
        }

        var recipients = this.recipients.map(recipient => {
          var iterableWidgets = JSON.parse(JSON.stringify(recipient.widgets));
          var widgets = iterableWidgets.map(widget => {
            var customText = this.getWidgetCustomTextFromRecipient(recipient);
            let docId;
            if (this.docId !== null) {
              docId = this.docId;
            } else {
              docId = widget.documentId.toString();
            }
            return {
              documentId: docId,
              widget: {
                customText: customText,
                page: widget.pageNumber,
                x: Math.round(widget.x),
                y: Math.round(widget.y),
                height: Math.round(widget.height * widget.scaleY),
                width: Math.round(widget.width * widget.scaleX)
              }
            };
          });
          var attachments = JSON.parse(JSON.stringify(recipient.attachments));
          var accessCode = {};
          if (recipient.authType === 20) {
            accessCode = {
              challenge: recipient.accessKeyChallenge,
              response: recipient.accessKeyResponse,
              format: recipient.accessKeyFormat
            };
          }
          return {
            name: recipient.name,
            email: recipient.email,
            cardId: recipient.cardId,
            phoneNumber: recipient.phoneNumber,
            actionType: recipient.role,
            authType: recipient.authType,
            accessCode: accessCode,
            attachments: attachments,
            widgets: widgets
          };
        });
        documentSet.recipients = recipients;

        var documents = this.files.map(async (document, index) => {
          let b64PDFContent = await FileToB64(document);
          let docId;
          if (this.docId !== null) {
            docId = this.docId;
          } else {
            docId = index.toString();
          }
          return {
            id: docId,
            name: document.name,
            b64PDFContent: b64PDFContent
          };
        });
        Promise.all(documents).then(result => {
          documentSet.documents = result;

          if (this.flow) {
            documentSet.flowTokenId = this.flow.flowTokenId;
          }

          if (this.activeUserTeam) {
            documentSet.teamId = this.activeUserTeam.teamTokenId;
          }
          this.isDocumentSetBeingCreated = true;
          this.$store
            .dispatch(
              this.flow ? "createNewPSCFlowSimpleItem" : "createNewPSCItem",
              documentSet
            )
            .then(response => {
              this.isDocumentSetBeingCreated = false;
              if (response.status === 200) {
                this.$router.back();
              } else {
                this.$toasted.global.toast_error({
                  message: "No se ha podido crear el sobre correctamente"
                });
              }
            })
            .catch(e => {
              if (e.response.status === 402) {
                this.errorMsg =
                  "No dispone de créditos suficientes. Contacte con el Dpto. Comercial.";
              } else {
                this.errorMsg = "No se ha podido crear el sobre correctamente";
              }
              this.isDocumentSetBeingCreated = false;
              this.$toasted.global.toast_error({
                message: this.errorMsg
              });
            });
        });
      }
    },

    getWidgetCustomTextFromRecipient(recipient) {
      if (recipient.includeCustomText) {
        var widgetCustomText = [
          {
            text: "Documento firmado electrónicamente",
            fontSize: 5
          },
          {
            text: "Por " + recipient.name,
            fontSize: 5
          },
          {
            text: "A las ##TIMESTAMP##",
            fontSize: 5
          }
        ];
        return widgetCustomText;
      } else {
        return [];
      }
    },

    checkNewRecipient() {
      this.newRecipient.phoneNumber = null;
      this.recipientDialogErrors = [];
      if (!this.newRecipient.name) {
        this.recipientDialogErrors.push(
          "Introduce un nombre válido para el participante."
        );
      }
      if (
        !this.newRecipient.email ||
        !/.+@.+\..+/.test(this.newRecipient.email)
      ) {
        this.recipientDialogErrors.push(
          "Introduce un email válido para el participante."
        );
      }

      var isEmailRepeated = this.recipients.some(
        recipient => recipient.email === this.newRecipient.email
      );
      if (isEmailRepeated) {
        this.recipientDialogErrors.push(
          "Introduce otro email. Ya existe un participante con este email."
        );
      }

      if (this.isPhoneRequiredForNewRecipient) {
        if (!this.newRecipientPhone.valid) {
          this.recipientDialogErrors.push(
            "Introduce un teléfono válido para el participante."
          );

          this.panelOpened = 0;
        }
      }

      if (this.isCardIdRequiredForNewRecipient && !this.newRecipient.cardId) {
        this.recipientDialogErrors.push(
          "Introduce un Card Id válido para el participante."
        );
      }

      if (this.isAccessKeyRequiredForNewRecipient) {
        if (
          !this.newRecipient.accessKeyChallenge ||
          !this.newRecipient.accessKeyResponse
        ) {
          this.recipientDialogErrors.push(
            "Introduce una pregunta y respuesta válida para el participante."
          );
        }
      }

      var isAnyAttachmentEmpty = this.newRecipient.attachments.some(
        attachment => !attachment.description
      );

      if (isAnyAttachmentEmpty) {
        this.recipientDialogErrors.push(
          "Introduce un nombre de Anexo válido para el participante."
        );
      }

      if (this.recipientDialogErrors.length === 0) {
        this.newRecipient.phoneNumber = this.newRecipientPhone.number.replace(
          /\s/g,
          ""
        );
        return true;
      }

      return false;
    },
    getFlows() {
      this.$store
        .dispatch("getFlows", {
          limit: 50,
          offset: 0,
          active: true
        })
        .then(data => {
          if (data) {
            let flowsTypeEnvelope = data.filter(flow => {
              return flow.flowType == 0;
            });

            this.itemsFlows = flowsTypeEnvelope.map(flow => {
              return { value: flow.flowTokenId, text: flow.name };
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.dialogMethodSign = false;
        });
    },
    openAndGetFlows() {
      if (this.itemsFlows && this.itemsFlows.length) {
        this.dialogMethodSign = true;
      } else {
        this.dialogMethodSign = false;
      }
    },
    setFlowData() {
      if (this.flowSelected) {
        this.isFlowDownloading = true;
        this.$store
          .dispatch("getFlow", this.flowSelected)
          .then(flow => {
            this.handleFlowResponse(flow);
            this.$toasted.global.toast_success({
              message: "Flujo seleccionado"
            });
          })
          .catch(() => {
            this.$toasted.global.toast_error({
              message: "No se ha podido obtener el detalle del flujo"
            });
          })
          .finally(() => {
            this.dialogMethodSign = false;
            this.isFlowDownloading = false;
            this.removeAllRecipients();
          });
      }
    },
    removeAllRecipients() {
      let recipients = this.recipients.length;

      if (recipients) {
        for (let index = 0; index < recipients; index++) {
          this.onRemoveRecipientPressed(0);
        }
        this.$toasted.global.toast_info({
          message: "Firmantes reiniciados"
        });
      }
    }
  }
};
</script>

<style>
.v-dialog__content--active,
.v-dialog__content {
  align-items: initial !important;
  height: auto !important;
  max-height: 80% !important;
  top: 15% !important;
}
.truncate {
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
  overflow: hidden; /* Oculta el texto que se desborda */
  text-overflow: ellipsis; /* Agrega puntos suspensivos cuando el texto se desborda */
}
</style>
